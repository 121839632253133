<template>
  <div class="component-container">
    <div class="content-container">
      <div class="type-block-container">
        <TypeBlock
          costType="spending"
          @query-page="queryPage"
          :list="listSpending"
          :defaultTyps="spendingTypes"
        />
        <TypeBlock
          costType="income"
          @query-page="queryPage"
          :list="listIncome"
          :defaultTyps="incomeTypes"
        />
      </div>
    </div>
  </div>
</template>

<script>
import TypeBlock from "./component/TypeBlock";
import Api from "@/api";
import { spendingTypes, incomeTypes } from "./component/defaultData.js";
export default {
  name: "TypeManage",
  components: {
    TypeBlock,
  },
  data() {
    return {
      spendingTypes,
      incomeTypes,
      listSpending: [],
      listIncome: [],
    };
  },
  mounted() {
    this.queryPage();
  },
  methods: {
    async queryPage() {
      const res = await Api.BillTypeApi.queryAll();
      if (res && res.code === 0) {
        const { records } = res.data;
        this.listSpending =
          records.filter((item) => item.method === "spending") || [];
        this.listIncome =
          records.filter((item) => item.method === "income") || [];
      } else {
        this.$message.error("分类请求失败");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.type-block-container {
  width: calc(100% - 40px);
  height: calc(100% - 40px);
  padding: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
</style>
