<template>
  <div class="type-block">
    <div class="type-block-title">{{ typeTitle }}内置类型</div>
    <div class="type-block-default">
      <BillTypeItem
        v-for="(item, index) in defaultTyps"
        :key="index"
        :billTypeData="item"
        class="default-type-block"
      />
    </div>
    <div class="type-block-title">
      <span>{{ typeTitle }}自定义类型</span>
      <el-button size="mini" style="margin-left: 20px" @click="handleAdd">
        新增
      </el-button>
    </div>
    <div class="type-block-custom">
      <BillTypeItem
        v-for="(tag, tagIndex) in list"
        :billTypeData="tag"
        class="custom-tag"
        :key="tagIndex"
        :showSetting="true"
        @handleEdit="handleEdit(tag)"
      />
    </div>

    <el-dialog
      :visible.sync="dialogVisible"
      width="1000px"
      :before-close="handleBeforeClose"
      class="type-dialog"
      :close-on-click-modal="false"
    >
      <el-form
        ref="type-form"
        :model="typeForm"
        label-width="80px"
        class="type-form"
      >
        <el-col :span="24">
          <el-form-item label="实时效果">
            <BillTypeItem :billTypeData="typeForm" class="custom-tag-sample" />
          </el-form-item>
        </el-col>
        <el-col :span="10">
          <el-form-item label="类型名称">
            <el-input v-model="typeForm.name"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="9">
          <el-form-item label="收支类型">
            <el-radio-group v-model="typeForm.method">
              <el-radio-button label="spending">支出</el-radio-button>
              <el-radio-button label="income">收入</el-radio-button>
            </el-radio-group>
          </el-form-item>
        </el-col>
        <el-col :span="5">
          <el-form-item label="颜色">
            <el-color-picker v-model="typeForm.color" size="small" />
          </el-form-item>
        </el-col>

        <el-col :span="24">
          <el-form-item label="选择标志">
            <div class="icon-group-container">
              <el-radio-group v-model="typeForm.icon" class="icon-group">
                <el-radio
                  class="icon-radio-item"
                  :label="icon"
                  v-for="(icon, indexIcon) in elementIconClassList"
                  :key="indexIcon"
                  border
                >
                  <i :class="icon" class="icon-element" />
                </el-radio>
              </el-radio-group>
            </div>
          </el-form-item>
          <el-form-item label="备注">
            <el-input
              type="textarea"
              :autosize="{ minRows: 2, maxRows: 4 }"
              placeholder="请输入备注"
              v-model="typeForm.remark"
            >
            </el-input>
          </el-form-item>
        </el-col>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button
          type="danger"
          @click="handleDelete"
          v-if="typeForm.id"
          :loading="deleteLoading"
        >
          删 除
        </el-button>
        <el-button type="primary" @click="handleSave" :loading="saveLoading">
          确 定
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { elementIconClassList } from "./defaultData.js";
import BillTypeItem from "@/components/BillTypeSelector/BillTypeItem.vue";
import Api from "@/api";

export default {
  name: "TypeBlock",
  components: { BillTypeItem },
  props: {
    costType: {
      type: String,
      default() {
        return "";
      },
    },
    list: {
      type: Array,
      default() {
        return [];
      },
    },
    defaultTyps: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  computed: {
    typeTitle() {
      let t = "";
      if (this.costType === "income") {
        t = "收入";
      } else if (this.costType === "spending") {
        t = "支出";
      }
      return t;
    },
  },
  data() {
    return {
      elementIconClassList,

      dialogVisible: false,
      dialogTitle: "",
      typeForm: {
        id: "", // id
        name: "",
        method: "", // "spending", "income"
        logo: "", // base64
        icon: "", // element-ui icon
        remark: "",
        color: "#188CFF", // #188cff
      },

      saveLoading: false,
      deleteLoading: false,
    };
  },
  methods: {
    async queryPage() {
      this.$emit("query-page");
    },
    /**
     * 打开新增对话框
     */
    handleAdd() {
      this.dialogVisible = true;
      this.dialogTitle = "新增类型";
      this.typeForm.method = this.costType;
    },
    handleEdit(tagItem) {
      this.dialogVisible = true;
      this.dialogTitle = "编辑类型";

      const { _id: id, name, method, icon, logo, remark, color } = tagItem;

      this.typeForm.id = id;
      this.typeForm.name = name;
      this.typeForm.method = method;
      this.typeForm.logo = logo;
      this.typeForm.icon = icon;
      this.typeForm.remark = remark;
      this.typeForm.color = color;
    },
    /**
     * 关闭对话框前的回调
     */
    handleBeforeClose() {
      this.handleClose();
    },
    /**
     * 关闭对话框
     */
    handleClose() {
      this.dialogVisible = false;
      ({ typeForm: this.typeForm, dialogTitle: this.dialogTitle } =
        this.$options.data());
    },
    /**
     * 保存对话框
     */
    async handleSave() {
      this.saveLoading = true;
      if (this.typeForm.id) {
        // 编辑
        const res = await Api.BillTypeApi.update(this.typeForm).finally(() => {
          this.saveLoading = false;
        });
        if (res && res.code === 0) {
          this.$message.success("编辑成功");
        } else {
          this.$message.error("编辑失败");
        }
      } else {
        // 新增
        const res = await Api.BillTypeApi.add(this.typeForm).finally(() => {
          this.saveLoading = false;
        });
        if (res && res.code === 0) {
          this.$message.success("新增成功");
        } else {
          this.$message.error("新增失败");
        }
      }
      this.handleClose();
      this.queryPage();
      ({ typeForm: this.typeForm, dialogTitle: this.dialogTitle } =
        this.$options.data());
    },
    /**
     * 删除一个tag
     */
    handleDelete() {
      this.$confirm(
        "确定要删除这个类型吗？会导致在账单列表里，显示为未知类型",
        "提示",
        {
          confirmButtonText: "确定，删除类型",
          confirmButtonStyle: "background-color: red",
          cancelButtonText: "取消，保留类型",
        }
      )
        .then(async () => {
          this.deleteLoading = true;
          const res = await Api.BillTypeApi.remove(this.typeForm.id).finally(
            () => {
              this.deleteLoading = false;
            }
          );
          if (res && res.code === 0) {
            this.$message.success("删除成功");
            this.queryPage();
            this.handleClose();
            ({ typeForm: this.typeForm, dialogTitle: this.dialogTitle } =
              this.$options.data());
          } else {
            this.$message.success("删除失败");
          }
        })
        .catch(() => {
          this.$message.info("取消了删除类型");
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.type-block {
  height: 100%;
  width: calc(50% - 10px);
  border-radius: 10px;
  box-shadow: 0px 0px 10px 5px rgb(206, 206, 206);
  .type-block-title {
    font-size: 18px;
    height: 20px;
    padding: 12px;
    border-bottom: 1px solid rgb(205, 205, 205);
    display: flex;
    align-items: center;
  }
  .type-block-default {
    height: 250px;
    background-color: rgb(243, 243, 243);
    display: flex;
    flex-wrap: wrap;
    overflow: auto;
    align-content: flex-start;

    .default-type-block {
      // border: 1px solid red;
      margin: 11px 0px 0px 11px;
    }
  }
  .type-block-custom {
    height: calc(
      100% - 45px - 45px - 250px - 40px
    ); // two 45px for two title, 40px is self padding
    padding: 20px;
    display: flex;

    flex-wrap: wrap;
    overflow: auto;
    align-content: flex-start;
  }

  .type-dialog {
    .type-form {
      .icon-group-container {
        .icon-group {
          border: 1px solid rgb(226, 226, 226);
          max-height: 270px;
          overflow-y: auto;
          .icon-radio-item {
            margin: 5px 0px 0px 5px;
            .icon-element {
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}

.custom-tag {
  margin: 11px 0px 0px 11px;
  width: fit-content;
}
.custom-tag-sample {
  margin: 4px 0px 0px 4px;
}
</style>
